import React, { useState } from "react"
import Card from "./card"

const Articles = props => {
  // const [sortType, setSortType] = useState("newest")
  const [limit, setLimit] = useState("50")
  // console.log(props.articles)

  // useEffect(() => {
  //   setSortType(props.defaultSort)
  //   if (props.limit) {
  //     setLimit(props.limit)
  //   }
  // }, [])

  return (
    <div className="columns">
      {/* {props.showSort ? (
        <p>
          <span
            className={
              sortType === "newest" ? "has-text-weight-bold" : "has-opacity-65"
            }
            onClick={() => setSortType("newest")}
          >
            NEWEST RESOURCES
          </span>{" "}
          /{" "}
          <span
            className={
              sortType === "best" ? "has-text-weight-bold" : "has-opacity-65"
            }
            onClick={() => setSortType("best")}
          >
            BEST RESOURCES
          </span>
        </p>
      ) : (
        ""
      )} */}

      {props.articles.slice(0, limit).map((article, i) => {
        return (
          <div className="column is-3" key={"article_" + article.node.strapiId}>
            <Card article={article} />
          </div>
        )
      })}

      {/* {props.articles && sortType === "best"
        ? props.articles.slice(0, limit).map((article, i) => {
            return (
              <Card
                article={article}
                key={"article_" + article.node.strapiId}
              />
            )
          })
        : props.articles
            .slice(0, limit)
            .map((article, i) => {
              return (
                <Card
                  article={article}
                  key={"article_" + article.node.strapiId}
                />
              )
            })} */}
    </div>
  )
}

export default Articles
