import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const Card = ({ article }) => {
  return (
    <div>
      <StyledCard className="card">
        <Link to={`/${article.node.title.replace(/\s+/g, '-').toLowerCase()}`} className="my-4 is-block">
          <div className="card-img">
            {article.node.image ? (
              <img src={article.node.image} alt={article.node.image} />
            ) : (
              ""
            )}
          </div>
          <div className="pt-2">
            <div className="my-4 is-block has-text-dark">
              <p className="is-size-5 has-text-weight-bold">
                {article.node.title}
              </p>
              <p className="has-opacity-65">{article.node.author}</p>
            </div>
          </div>
        </Link>
      </StyledCard>
    </div>
  )
}

export default Card

const StyledCard = styled.div`
  .card-img {
    height: 300px;
  }
  img {
    height: 100%;
    width: auto;
    display: block;
    margin: auto;
  }
  @media (max-width: 768px) {
    margin-bottom: 32px;
    .card-img {
      width: auto;
    }
    & > div {
      margin: 0 !important;
      padding: 0 !important;
    }
  }
`
